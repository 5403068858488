<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="706"
    :closable="false"
    centered
    :maskClosable="false"
    :footer="null"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>选择产品
      </span>
    </div>
    <div class="cancelContent">
       <div class="tabBox">
        <p
          v-for="(item,index) in typeList"
          :key="index"
          @click="changeType(index)"
          :class="tabIndex == item.index?'tabActive':'tab'"
          class=""
        >
          {{item.name}}
          <span class="bottomBlock"></span>
        </p>

       </div>
      <div class="productItemBox" v-show="tabIndex == 0">
<!--        dms的购买记录-->
        <div
            v-show="dmsBuyProductList.length > 0 "
            v-for="(item,index) in dmsBuyProductList"
            :key="index"
            :class="checkId == item.id?'proItemActive':'proItem'"
            @click="changePro(item)"
          >
            <p class="modelName">{{item.model}}</p>
            <p class="time">{{item.documentDate}}</p>
          </div>
        <div class="noList" v-show="dmsBuyProductList.length <= 0 ">
          <img src="@/assets/IntentionList/none.png" alt="">
          <p>暂无已购产品记录~</p>
        </div>
      </div>
      <div class="otherProItemBox" v-show="tabIndex == 1">
        <div
          class="bigBox"
          v-for="(item,index) in productArr"
          :key="index"
        >
<!--          @click="changePro(item)"-->
          <div  class="proItem" @click.stop="changeZK(item,index)">
            <div >
              <img :src="item.url" alt="">
              <p class="modelName">{{item.spartName}}</p>
            </div>
            <i v-if="!item.zk" @click.stop="changeZK(item,index)" style="transform: rotate(90deg);color:#aaa" class="iconfont icon-arrow-right-small-copy"></i>
            <i v-if="item.zk" @click.stop="changeZK(item,index)" style="transform: rotate(270deg);color:#aaa" class="iconfont icon-arrow-right-small-copy"></i>

          </div>
          <div class="childBox" v-if="item.zk">
            <p
              class="child"
              v-for="itemTwo in item.children"
              :key = itemTwo.spartCode
              @click="changeOtherPro(item,itemTwo)"
            >
              {{itemTwo.spartName}}

            </p>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name:'addproModal',
  data(){
    return{
      typeList:[
        {
          index:0,
          name:'已购产品'
        },
        {
          index:1,
          name:'其他产品'
        },
      ],
      // buyProductList:[],
      tabIndex:0,
      checkId:'',


    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },

    productArr:{
      type: Array,
      default: () => []
    },

    dmsBuyProductList:{
      type: Array,
      default: () => []
    },


  },
  watch: {
    productArr:{
      handler: function(val, oldVal) {
      },
      immediate: true
    },
    dmsBuyProductList:{
      handler: function(val, oldVal) {

      },
      immediate: true
    },

    visible:{
      handler: function(val, oldVal) {
       if(!this.visible){
         this.dmsBuyProductList = []
         this.checkId = []
         this.productArr = []
       }else {
         if(this.productArr.length > 0 ){
           this.productArr.forEach(i => {
             i.zk = false;
           })
         }
       }
      },
      immediate: true
    },


  },
  mounted(){
  },
  methods:{
    // 展开收起图标
    changeZK(item,index){
      // item.zk = !item.zk
      // this.$forceUpdate()
      this.productArr.forEach(i => {
        // 判断如果数据中的menuList[i]的show属性不等于当前数据的isSubShow属性那么menuList[i]等于false
        if (i.zk !== this.productArr[index].zk) {
          i.zk = false;
        }
      });
      item.zk = !item.zk;
    },
    // 切换产品类型
    changeType(index){
      this.tabIndex = index
      this.productArr.forEach(i => {
        i.zk = false;
      });
    },
    // 修改产品 已购产品（cis）
    changePro(item){
      this.checkId = item.id
      this.$emit('changePro',item)
      this.$emit('update:visible',false)
      this.$forceUpdate()
    },
    // 修改其他品牌商品
    changeOtherPro(item,twoPro){
      this.$emit('changeOtherPro',item,twoPro)
      this.$emit('update:visible',false)

    },


    changeInput(value){


    },
    handleOk() {

      this.$emit('update:visible',false)
      this.$forceUpdate()
    },
    cancle(){
      this.$emit('update:visible',false)
      this.$forceUpdate()
    },

  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{

  .ant-modal-body{
    padding:30px 30px 50px 30px;

  }
  .ant-modal-content{
    min-height:400px;
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .cancelContent{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 706px;
        padding:0 30px;
        .tabBox {
          display: flex;
          flex-direction: row;
          .tab{
            width: 61px;
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #777777;
            line-height: 20px;
            margin-right: 19px;
            cursor: pointer;
            .bottomBlock {
             display: none;
            }
          }
          .tabActive {
            width: 61px;
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
            margin-right: 19px;
            position: relative;
            cursor: pointer;
            .bottomBlock {
              width: 16px;
              height: 4px;
              background: linear-gradient(90deg, #57D9D3 0%, #2BD8B5 100%);
              border-radius: 2px;
              backdrop-filter: blur(10px);
              position: absolute;
              bottom: -7px;
              left: 23px
            }
          }


        }
        .productItemBox {
          height: 100%;
          margin-top:10px;
          display: flex;
          flex-direction:row;
          flex-wrap: wrap;
          .proItem {
            width: 317px;
            height: 32px;
            background: #F7F7F7;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;
            padding: 0 12px;
            cursor: pointer;
            .modelName {
              font-weight: 400;
              color: #262626;
              line-height: 20px;
              font-size: 14px;
            }
            .time {
              font-size: 14px;
              font-weight: 400;
              color: #777777;
              line-height: 20px;
            }
          }
          .proItemActive {
              width: 317px;
              height: 32px;
              background: #F7F7F7;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #00AAA6;
              margin-top: 12px;
              padding: 0 12px;
              cursor: pointer;
              .modelName {
                font-weight: 400;
                color: #00AAA6;
                line-height: 20px;
                font-size: 14px;
              }
            .time {
              font-size: 14px;
              font-weight: 400;
              color: #00AAA6;
              line-height: 20px;
            }
        }
          .proItem:nth-child(2n+1) {
            margin-right: 12px;
          }
          .proItemActive:nth-child(2n+1) {
            margin-right: 12px;
          }
          .noList{
            width: 100%;
            margin:0 auto;
            margin-top: 20px;
            img {
              width:150px;
            }
            P{
              color: #777777;
              font-size: 14px;
            }

          }
        }
        // 其他品类产品
        .otherProItemBox {
          height: 100%;
          margin-top:10px;
          display: flex;
          flex-direction:row;
          flex-wrap: wrap;
          .bigBox {
            display: flex;
            flex-direction: column;
            width:210px;
            position: relative;
            margin-right: 8px;
            .proItem {
              width: 210px;
              height: 32px;
              background: #F7F7F7;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 12px;
              padding: 0 12px;
              cursor: pointer;
              margin-right: 8px;
              div {
                display:flex;
                flex-direction: row;
                align-items: center;
                img{
                  margin-right: 8px;
                  width: 24px;
                  height: 24px
                }
                .modelName {
                  font-weight: 400;
                  color: #262626;
                  line-height: 20px;
                  font-size: 14px;
                }
                .time {
                  font-size: 14px;
                  font-weight: 400;
                  color: #777777;
                  line-height: 20px;
                }
              }
              .selectBox {
                position: absolute;
                bottom:0;
                background: #fff;
                height: 100%;
                .selectItem {
                  width: 210px;
                  height: 32px;
                  background: #FFF;
                }
                .selectItemActivity{
                  width: 210px;
                  height: 32px;
                  background: #F7F7F7;
                }
              }
            }

            .childBox {
              position: absolute;
              top: 50px;
              z-index:800;
              width: 210px;
              //height: 100%;
              background: #FFFFFF;
              box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.17);
              border-radius: 4px;
              text-align:left;
              .child {
                width: 210px;
                height: 32px;
                background: #FFF;
                font-size: 14px;
                color: #262626;
                line-height: 32px;
                padding-left: 16px;
                cursor: pointer;
              }
              .childActive{
                width: 210px;
                height: 32px;
                background: #F7F7F7;
                font-size: 14px;
                color: #262626;
                line-height: 32px;
                padding-left: 16px;
                cursor: pointer;
              }
              .child:last-child{
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
              .childActive:last-child{
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
              .child:first-child{
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
              .childActive:first-child{
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
              .child:hover{
                background: #f7f7f7;
              }
              .childActive:hover{
                background: #f7f7f7;
              }
            }
          }
          .bigBox:nth-child(3n+3) {
            margin-right: 0px;
          }



        }
        //.clickBtn{
        //  padding: 0 14px 0 14px;
        //  height: 32px;
        //  border-radius: 16px;
        //  border: 1px solid #EEEEEE;
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //  color: #00AAA6;
        //  margin-right: 8px;
        //  margin-bottom: 16px;
        //  cursor: pointer;
        //  font-size: 13px;
        //  font-weight: 400;
        //}
        //.clickBtnActive {
        //  cursor: pointer;
        //  margin-right: 8px;
        //  padding: 0 14px 0 14px;
        //  height: 32px;
        //  border-radius: 16px;
        //  //border: 1px solid #EEEEEE;
        //  display: flex;
        //  background: #00AAA6;
        //  justify-content: center;
        //  align-items: center;
        //  color: #fff;
        //  margin-bottom: 16px;
        //  border: none;
        //  font-size: 13px;
        //  font-weight: 400;
        //}
      }
      .cancelInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        .cancleZi{
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }
        .ant-input{
          width: 300px;
          height:32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper{
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:50px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    p{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>


