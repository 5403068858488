import http from "@/utils/request";
// 模板列表
export function findPosterTemplatePage(params) {
  return http({
    url: '/exclusiveActivity/findPosterTemplatePage.nd',
    method: 'get',
    params
  })
}
// 提交专卖店活动
export function createOrEdit(data) {
  return http({
    url: '/exclusiveActivity/createOrEdit.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}
// 查询详情接口
export function getDetail(params) {
  return http({
    url: '/exclusiveActivity/detail.nd',
    method: 'get',
    params
  })
}

// 生成图片
export function makeImg( data) {
  return http({
    url: 'newCreateImage/newCreateImage.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}

export function findTemplateDetail(params) {
  return http({
    url: '/exclusiveActivity/findTemplateDetail.nd',
    method: 'get',
    params
  })
}

// /exclusiveActivity/findTemplateDetail.nd
export function save( data) {
  return http({
    url: '/reportInstallRepair/save.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}
// 获取跟进人接口
export function findFollowPeople(params) {
  return http({
    url: '/customer/findFollowPeople.nd',
    method: 'get',
    params
  })
}
