import http from "@/utils/request";
// 意向用户详情
export function getUser(data) {
  return http({
    method: "post",
    url: "/shopPotentialUser/getUser.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}
// 获取销售信息
export function getSaleListInfo(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialProduct/page.nd",
    data
  });
}
// 保存销售记录
export function saveSale(data) {
  return http({
    method: "post",
    url: "/shopPotentialProduct/save.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 修改销售记录
export function upDateSale(data) {
  return http({
    method: "post",
    url: "/shopPotentialProduct/update.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}

// 获取购买记录
export function BuyRecord(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialBuyRecord/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}
// 获取售后记录
export function AfterSales(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialAfterSales/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}

// 删除
export function deletsale(data) {
  return http({
    method: "post",
    url: "/shopPotentialProduct/delete.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
export function custAccountDict(data) {
  return http({
    method: "post",
    url: "/fast/userReport/custAccountIdDict.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}

// 获取跟进记录
export function shopPotentialVisit(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialVisit/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}
// 去跟进接口
export function updateShopPotentialVisit(data) {
  return http({
    method: "post",
    url: "/shopPotentialVisit/update.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 定计划接口
export function saveShopPotentialVisit(data) {
  return http({
    method: "post",
    url: "/shopPotentialVisit/save.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}