import {
  SalesShopInfoList,
  querySpart,
  findRetailRecordByUserPhone,
  queryBrandByProductCode,
  queryBrandBySpartId, queryRepairOrderInfo
} from "./api";
import { getAdressapi } from "@/views/settlement/api";
import moment from "moment";
import { publicPath } from "@/common/constant";
import { save } from "@/views/monopolyShopActivity/creatStoreActivities/api";
import { BuyRecord } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import  addproModal  from "./components/addPro"
export default {
  components: {
    addproModal
  },
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/installationRepairReporting/workOrderSubmission",
          name: "",
          title: "工单提报"
        }
      ],
      saleShopList:[], // 所属门店
      receiveShop:undefined, // 所属门店名称
      salesShopInfoId:'',  // 所属门店id
      userDetailAddress:'', // 详细地址
      adressList:[],
      province:'',
      city:'',
      area:'',
      Township:'',
      productArr: [],// 产品
      form:{
        name:'',
        storeName:'',
        phone:'',
        salesShopCisCode:'',
        // brandCode:'',
        // brandName:'',
        // brandId:'',
        // buyTime:'',
        // describe:'',
        // planTime:'',
        // reportRange:'',
        // reportRangeId:'',
        // logSpartName:'',
        // colour:''
      },
      brandArr:[],
      dmsBrandFlag:false,
      timeGroup:[
        {
          id:2,
          name:'上午8:30-12:30'
        },
        {
          id:3,
          name:'下午12:30-17:30'
        },
        {
          id:4,
          name:'晚上17:30-20:30'
        },
        {
          id:1,
          name:'全天8:30-20:30'
        },
      ],
      fileList:[],
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      previewVisible:false,
      previewImage:'',
      fileListIds:[],
      modelViseible:false,
      spartItemName:'',// 二级品类名称
      spartItemCode:'',// 二级品类名称
      spartItemId:'',// 二级品类名称
      spartName:'', // 一级品类名称
      spartCode:'', // 一级品类编码
      spartId:'', // 一级品类id
      pageLoadFlag:false,
      type:1,
      addressHx:[],
      userId:'',
      buyId:'',
      cisProductList:[],
      dmsProductList:[],
      productCode:'',
      installFlag:'1',
      procuctHX:[],
      visiblePro:false,
      dmsBuyProductList:[],// dms 已购产品接口
      productShowList:[
        {
          productCode:'', //不带颜色的型号，与下面六个spart开头的参数只存其一即可    没有传null
          logSpartName:'', // 购买记录返回的品类名称，与productCode同时存在   与下面六个spart开头的参数只存其一即可    没有传nul
          spartName:'', // 一级品类名称
          spartCode:'', // 一级品类编码
          spartId:'', // 一级品类id
          spartItemName:'',// 二级品类名称
          spartItemCode:'',// 二级品类名称
          spartItemId:'',// 二级品类名称
          brandName:'',// 品牌名 必传
          brandCode:'',
          brandId:'', //  品牌编码 必传
          installRepairType:1, //installRepairType 1装2修 数字类型别带双引号
          describe:'',
          reportRange:undefined,
          reportDateStr:'',
          fileListIds:[], // scripUrl
          buyId:'',
          buyTime:'', // buyDate
          buyDateStr:'', // buyDateStr
          planTime:'',
          reportRangeId:'',
          num:1,
          procuctHX:[],
          colour:'',
          fileList:[],
          brandArr:[],
        },
      ],// 总数据
      editProItem:{},
      editProIndex:'',
      messageInfo:'',
      showMesaageFlag:false,
      flag:false,// 判断跳不跳转
    }
  },
  mounted() {
    this.getsaleShopList()
    this.getOtherProduct(this.productShowList[0].installRepairType)
    if(this.$route.query.type){
      this.type = this.$route.query.type
    }
    this.contactPersonTel = this.$store.state.user.userInfo.account.mobile
        ? this.$store.state.user.userInfo.account.mobile : ''
  },
  methods: {
    // 商品行数据删除
    delectProItem(item, index) {
      if (this.productShowList.length > 1) {
        this.productShowList.splice(index, 1);
      }
    },
    openAdd(){
      this.productShowList.push(
          {
            brandCode:'', //不带颜色的型号，与下面六个spart开头的参数只存其一即可    没有传null
            logSpartName:'', // 购买记录返回的品类名称，与productCode同时存在   与下面六个spart开头的参数只存其一即可    没有传nul
            spartName:'', // 一级品类名称
            spartCode:'', // 一级品类编码
            spartId:'', // 一级品类id
            spartItemName:'',// 二级品类名称
            spartItemCode:'',// 二级品类名称
            spartItemId:'',// 二级品类名称
            brandName:'',// 品牌名 必传
            brandId:'', //  品牌编码 必传
            installRepairType:this.type==4?2: 1, //installRepairType 1装2修 数字类型别带双引号
            describe:'',
            reportRange:undefined,
            fileListIds:[], // scripUrl
            buyId:'',
            buyTime:'', // buyDate
            buyDateStr:'', // buyDateStr
            planTime:'',
            reportRangeId:'',
            num:1,
            procuctHX:[],
            colour:'',
            fileList:[],
            brandArr:[],
          },
      )

    },
    goBack(){
      this.$router.go(-1);
    },
    getDetail(){
      this.pageLoadFlag = true
      // 从用户运营列表过来的数据
      if(this.$route.query.form == 'list') {
        let listUserInfo = JSON.parse(this.$route.query.item)
        this.form.name = listUserInfo.userName
        this.form.phone = listUserInfo.phone
        this.addressHx = [listUserInfo.provinceCode,listUserInfo.cityCode,listUserInfo.districtCode,listUserInfo.townCode]
        this.province = this.addressHx[0];
        this.city = this.addressHx[1];
        this.area = this.addressHx[2];
        this.Township = this.addressHx[3];
        this.userDetailAddress = listUserInfo.address
        this.salesShopCisCode = listUserInfo.shopCisCode
        let obj = this.saleShopList.filter(it=>it.cisCode ==  this.salesShopCisCode)
        this.salesShopInfoId = obj.length > 0 ? String( obj[0].id ):''
        this.receiveShop = obj.length > 0 ? obj[0].fullName :''
        this.getDMSBuyList()
      }
      // 从购买记录过来的数据 进行重组
      if(this.$route.query.type == 3){
        let UserInfoOne = JSON.parse(this.$route.query.item)
        let UserInfo = [JSON.parse(this.$route.query.item)]
        const mergedArray = UserInfo.map(obj1 => {
          const obj2 = this.productShowList[0];
          return { ...obj2, ...obj1 };
        });
        this.form.name = UserInfoOne.userName
        this.form.phone = UserInfoOne.phone
        this.addressHx = [UserInfoOne.provinceCode,UserInfoOne.cityCode,UserInfoOne.districtCode,UserInfoOne.townCode]
        this.province = this.addressHx[0];
        this.city = this.addressHx[1];
        this.area = this.addressHx[2];
        this.Township = this.addressHx[3];
        this.userDetailAddress = UserInfoOne.address
        this.salesShopInfoId = UserInfoOne.shopInfoId
        let obj = this.saleShopList.filter(it=>it.id ==  this.salesShopInfoId)
        this.salesShopCisCode = obj.length > 0 ? String( obj[0].cisCode ):''
        this.receiveShop = obj.length > 0 ? obj[0].fullName :''
        this.userId = UserInfoOne.userId
        mergedArray.forEach(async(itemProduct) => {
          itemProduct.buyId = itemProduct.id
          itemProduct.installFlag = itemProduct.installFlag=='Y'?1:0
          if(itemProduct.installFlag == 'N') {
            itemProduct.installRepairType = 2
          }
          itemProduct.productCode = itemProduct.product
          itemProduct.logSpartName= itemProduct.spartName
          itemProduct.buyTime = itemProduct.buyTimeStr?itemProduct.buyTimeStr.split(' ')[0]:''
          itemProduct.procuctHX =  itemProduct.productCode
          const brandRsponse = await this.getBrandNew(itemProduct.productCode,itemProduct)
          itemProduct.brandArr.push(brandRsponse.data.data)
          itemProduct.brandId = (itemProduct.brandArr.length > 0 && itemProduct.brandCode) && itemProduct.brandArr.filter(it => (it.brandCode == itemProduct.brandCode)).length>0 ?
              itemProduct.brandArr.filter(it => (it.brandCode == itemProduct.brandCode))[0].brandId
              :''
          this.getDMSBuyList()
          this.productShowList = mergedArray
        })
      }
      // 从销售订单过来的数据
      if(this.$route.query.type == 2){
        let productInfo = JSON.parse(this.$route.query.proItem)
        let saleInfo = JSON.parse(this.$route.query.item)

        // 获取的数据和原来定义的数据混合
        const mergedArray = productInfo.map(obj1 => {
          const obj2 = this.productShowList[0];
          return { ...obj1, ...obj2 };
        });


        this.form.name = saleInfo.customerName
        this.form.phone = saleInfo.phone
        this.addressHx = [saleInfo.provinceId,saleInfo.cityId,saleInfo.countyId,saleInfo.townId]
        this.province = this.addressHx[0];
        this.city = this.addressHx[1];
        this.area = this.addressHx[2];
        this.Township = this.addressHx[3];
        this.userDetailAddress = saleInfo.addressDesc
        this.salesShopCisCode= saleInfo.storeCode
        let obj = this.saleShopList.filter(it=>it.cisCode ==  this.salesShopCisCode)
        this.salesShopInfoId = obj.length > 0 ? String( obj[0].id ):''
        this.receiveShop = obj.length > 0 ? obj[0].fullName :''
        this.userId = ''

        mergedArray.forEach(async(itemProduct)=> {
          itemProduct.installFlag = itemProduct.installFlag=='Y'?1:0
          if(itemProduct.installFlag == 'N') {
            itemProduct.installRepairType = 2
          }
          itemProduct.buyId = ''
          itemProduct.brandArr=[]
          itemProduct.productCode = itemProduct.model
          itemProduct.logSpartName= itemProduct.spart
          itemProduct.buyTime = saleInfo.documentDate?saleInfo.documentDate.split(' ')[0]:''
          itemProduct.procuctHX =  itemProduct.model

          const brandRsponse = await this.getBrandNew(itemProduct.productCode,itemProduct)
          itemProduct.brandArr.push(brandRsponse.data.data)
          this.$forceUpdate()
          if(this.type !== 3) {
            itemProduct.brandId = itemProduct.brandArr.length > 0 ? itemProduct.brandArr[0].brandId : ''
            itemProduct.brandName =  itemProduct.brandArr.length > 0 ?  itemProduct.brandArr[0].brandName: ''
            itemProduct.brandCode =  itemProduct.brandArr.length > 0 ?  itemProduct.brandArr[0].brandCode: ''
            this.$forceUpdate()
          }
        })
        this.getDMSBuyList()
        this.productShowList = mergedArray
        this.$forceUpdate()
      }
      // 从工单查询过来的数据 进行查询详情 给页面赋值
      if(this.$route.query.type == 4) {
        queryRepairOrderInfo({reportInstallRepairId:this.$route.query.id}).then(res=>{
          let productInfo = []
          productInfo.push(res.data.data)
          // 获取的数据和原来定义的数据混合
          const mergedArray = productInfo.map(obj1 => {
            const obj2 = this.productShowList[0];
            return { ...obj2,...obj1};
          });
          this.form.name = res.data.data.userName
          this.form.phone = res.data.data.userPhone
          this.addressHx = [res.data.data.provinceCode,res.data.data.cityCode,res.data.data.districtCode,res.data.data.townCode]
          this.province = this.addressHx[0];
          this.city = this.addressHx[1];
          this.area = this.addressHx[2];
          this.Township = this.addressHx[3];
          this.userDetailAddress = res.data.data.userAddress
          this.salesShopInfoId= res.data.data.shopId?String(res.data.data.shopId):''
          this.salesShopCisCode = res.data.data.shopCisCode?String(res.data.data.shopCisCode):''
          this.receiveShop =  res.data.data.shopName?res.data.data.shopName:''
          mergedArray.forEach(async(itemProduct)=> {
            itemProduct.id = this.$route.query.id
            itemProduct.installFlag = 0
            itemProduct.installRepairType = 2
            itemProduct.buyId = ''
            itemProduct.brandArr=[]
            // itemProduct.logSpartName= itemProduct.spart
            itemProduct.buyTime = res.data.data.buyDate?this.$util.timestampConversion(res.data.data.buyDate):''
            itemProduct.planTime = res.data.data.reportDate?this.$util.timestampConversion(res.data.data.reportDate):''
            itemProduct.procuctHX =  itemProduct.productCode
            if(itemProduct.reportRange == 1){
              itemProduct.reportRangeId = 1
            }
            if(itemProduct.reportRange == 2){
              itemProduct.reportRangeId = 2
            }
            if(itemProduct.reportRange == 3){
              itemProduct.reportRangeId = 3
            }
            if(itemProduct.reportRange == 4){
              itemProduct.reportRangeId = 4
            }
            const brandRsponse = await this.getBrandNew(itemProduct.productCode,itemProduct)
            itemProduct.brandArr.push(brandRsponse.data.data)
            this.$forceUpdate()
            if(this.type !== 3) {
              itemProduct.brandId = itemProduct.brandArr.length > 0 ? itemProduct.brandArr[0].brandId : ''
              itemProduct.brandName =  itemProduct.brandArr.length > 0 ?  itemProduct.brandArr[0].brandName: ''
              itemProduct.brandCode =  itemProduct.brandArr.length > 0 ?  itemProduct.brandArr[0].brandCode: ''
              this.$forceUpdate()
            }
          })
          this.getDMSBuyList()
          this.productShowList = mergedArray
          this.$forceUpdate()
        })


      }
      this.pageLoadFlag = false
    },
    // 获取品牌
    getBrand( productCode,itemProduct){
      itemProduct.brandArr = []
      queryBrandByProductCode({productCode: productCode,colour:itemProduct.colour}).then(res => {
        if(res.data.code == 0) {
          itemProduct.brandArr.push( res.data.data)
          if(this.type !== 3) {
            itemProduct.brandId = this.brandArr.length > 0 ? this.brandArr[0].brandId : ''
            itemProduct.brandName =  this.brandArr.length > 0 ?  this.brandArr[0].brandName: ''
            itemProduct.brandCode =  this.brandArr.length > 0 ?  this.brandArr[0].brandCode: ''
          }
        }
      })
    },
    getBrandNew( productCode,itemProduct){
      return queryBrandByProductCode({productCode: productCode,colour:itemProduct.colour})
    },
    //查看
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //上传状态改变
    handleChange1(info,item) {
      if (info.file.size  / 1024 / 1024 > 10) {
        return;
      }
      item.fileList = info.fileList;
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          item.fileList = info.fileList;
          item.fileListIds.push(info.file.response.url)
          //赋值头像Id
          // this.infoForm.headPicId = info.file.response.businessId;
        } else {
          this.$message.warning("上传失败");
        }
      }
      // if(info.file.status === "removed"){
      //   //删除置空
      //   // this.infoForm.headPicId = '';
      // }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //头像上传
    beforeUpload(file,item) {
      if (file.size / 1024 / 1024 <= 20) {
        item.fileList = [...item.fileList, file];
      } else {
        this.$message.info("文件超出20M,请重新上传");
      }
      // return false;
    },
    openView(item){
      if(item.type.indexOf('image/') !== -1 ){
        this.previewImage = item.response.url
        this.modelViseible = true
      }
      if(item.type.indexOf('application/') !== -1 || item.type.indexOf('video/') !== -1){
        window.open(item.response.url, "_blank")
      }
    },
    // 删除附件
    delect(item,index,itemList){
      const newFileList = itemList.fileList.slice();
      newFileList.splice(index, 1);
      itemList.fileList = newFileList;
      itemList.fileListIds.splice(index, 1);
    },

    //取消
    handleCancel() {
      this.previewVisible = false;
    },
    //上传格式错误
    errorAcceptfun(e, e1) {
      console.log(e, e1);
    },
    // 时间更改
    handleTimeChange(e, defaultTime,index,item) {
      item.buyTime = defaultTime;
    },
    handlePlanTimeChange(e, defaultTime,index,item) {
      item.planTime = defaultTime;
    },
    handlereportRangeList(value, e,index,item){
      item.reportRangeId = e.data.key
    },
    // 获取已购产品DMS
    getDMSBuyList() {
      let data = {
        phone: this.form.phone,
        storeCode:  this.salesShopCisCode,
      }
      this.dmsBuyProductList = []
      findRetailRecordByUserPhone(data).then(res=>{
        let data = JSON.parse(res.data)
        if (data.code == 0) {
          this.dmsBuyProductList = data.data
        }
      })
    },
    // 获取已购产品DMS
    getDMSBuyListNew(item) {
      let data = {
        phone: this.form.phone,
        storeCode:  this.salesShopCisCode,
      }
      return findRetailRecordByUserPhone(data)
      // item.dmsBuyProductList = []
      // findRetailRecordByUserPhone(data).then(res=>{
      //   let data = JSON.parse(res.data)
      //   if (data.code == 0) {
      //     item.dmsBuyProductList = data.data
      //   }
      // })
    },
    // 获取其他产品CIS
    getOtherProduct(type){
      this.productArr = []
      querySpart({}).then(res=>{
        res.data.data.forEach(item=>{
          item.zk = false
          item.url= `${publicPath}/assets/images/report/${item.spartCode}.png`
          if(item.installFlag == 1  &&  type == 1 ) {
            this.productArr.push({
              ...item,
              children: item.reportInstallRepairSpartList.map(itemTwo => {
                // installFlag是1的可发起安装   repairFlag是1的可发起维修
                if(itemTwo.installFlag == 1 &&  type == 1) {
                  return itemTwo
                }
                if(itemTwo.repairFlag == 1 &&  type == 2) {
                  return itemTwo
                }
              })
            })
          }
          if(item.repairFlag == 1  &&  type == 2 ) {
            this.productArr.push({
             ...item,
              children: item.reportInstallRepairSpartList.map(itemTwo => {
                // installFlag是1的可发起安装   repairFlag是1的可发起维修
                if(itemTwo.installFlag == 1 &&  type == 1) {
                  return itemTwo
                }
                if(itemTwo.repairFlag == 1 &&  type == 2) {
                  return itemTwo
                }
              })
            })
          }
        })
      })
    },
    disabledDate(current) {
      // return current && current <= moment().subtract(1, 'days').endOf('day')
      // 27612-更改为不能选择今天日期 只能修改今天以后的
      return current && current < moment().endOf('day')
    },
    disabledDate1(current) {
      return current && current > moment().endOf("day");
    },

    // 修改产品
    onChange(item,index) {
      this.editProIndex = index
      if(!this.form.phone){
        this.$message.warning('请输入手机号')
        // this.$refs.aInput.blur()
        this.$refs.aInput[index].blur()
        return
      }
      if(!this.receiveShop){
        this.$message.warning('请选择所属门店')
        // this.$refs.aInput.blur()
        this.$refs.aInput[index].blur()
        return
      }
      this.visiblePro = true
      // this.$refs.aInput.blur()
      this.$refs.aInput[index].blur()
      return
    },
    // 选择其他产品 后续处理
    changePro(item){
      // 回显数据 为选中数据
      this.productShowList[this.editProIndex].id = ''
      this.productShowList[this.editProIndex].procuctHX = item.model
      this.productShowList[this.editProIndex].logSpartName = item.spart
      this.productShowList[this.editProIndex].productCode = item.model
      this.productShowList[this.editProIndex].colour = item.colour
      this.productShowList[this.editProIndex].buyTime = item.documentDate
      this.productShowList[this.editProIndex].spartName = ''
      this.productShowList[this.editProIndex].spartCode = ''
      this.productShowList[this.editProIndex].spartId = ''
      this.productShowList[this.editProIndex].spartItemName= ''
      this.productShowList[this.editProIndex].spartItemCode = ''
      this.productShowList[this.editProIndex].spartItemName = ''
      this.productShowList[this.editProIndex].brandArr=[]
      this.productShowList[this.editProIndex].installFlag = item.installFlag
      queryBrandByProductCode({productCode:this.productShowList[this.editProIndex].procuctHX,colour:this.productShowList[this.editProIndex].colour}).then(res => {
        this.productShowList[this.editProIndex].brandArr.push( res.data.data)
        this.productShowList[this.editProIndex].brandName =  this.productShowList[this.editProIndex].brandArr.length > 0 ?  this.productShowList[this.editProIndex].brandArr[0].brandName: ''
        this.productShowList[this.editProIndex].brandCode =  this.productShowList[this.editProIndex].brandArr.length > 0 ?  this.productShowList[this.editProIndex].brandArr[0].brandCode: ''
        this.productShowList[this.editProIndex].brandId =  this.productShowList[this.editProIndex].brandArr.length > 0 ?  this.productShowList[this.editProIndex].brandArr[0].brandId: ''
      })
      this.visiblePro = false
    },
    // 修改其他产品
    async changeOtherPro($event, value){
      this.productShowList[this.editProIndex].id = ''
      this.productShowList[this.editProIndex].procuctHX = value?value.spartName:''
      this.productShowList[this.editProIndex].productCode = ''
      this.productShowList[this.editProIndex].logSpartName = ''
      this.productShowList[this.editProIndex].spartName = $event?$event.spartName:''
      this.productShowList[this.editProIndex].spartCode = $event?$event.spartCode:''
      this.productShowList[this.editProIndex].spartId = $event?$event.id:''
      this.productShowList[this.editProIndex].spartItemName =  value?value.spartName:''
      this.productShowList[this.editProIndex].spartItemCode =  value?value.spartCode:''
      this.productShowList[this.editProIndex].spartItemId =  value?value.id:''
      this.productShowList[this.editProIndex].colour = value?value.colour:''
      this.productShowList[this.editProIndex].installFlag = value?value.installFlag:''

      let data  = {
        spartId:$event.id
      }
      queryBrandBySpartId(data).then(res => {
        this.productShowList[this.editProIndex].brandArr = res.data.data
        this.productShowList[this.editProIndex].brandCode = ''
        this.productShowList[this.editProIndex].brandName = ''
        this.productShowList[this.editProIndex].brandId = ''
      })
      this.visiblePro = false
    },
    // 点击
    changeSorceBtn(brandItem,item) {
      if(item.brandName == brandItem.brandName) {
        item.brandCode = ''
        item.brandName = ''
        item.brandId = ''
        return
      }
      item.brandName = brandItem.brandName
      let obj = item.brandArr.filter(it=>it.brandName == item.brandName)
      item.brandName = obj.length > 0 ? obj[0].brandName :''
      item.brandCode = obj.length > 0 ? obj[0].brandCode : ''
      item.brandId = obj.length > 0 ? obj[0].brandId : ''
      this.$forceUpdate();
    },
    // 详细地址
    blurAddress(){
      if(!this.Township) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(!this.Township) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.adressList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.adressList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.Township
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.adressList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.adressList =[]
        }
        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    onAddressChange (addressValArr) {
      this.province = addressValArr[0];
      this.city = addressValArr[1];
      this.area = addressValArr[2];
      this.Township = addressValArr[3];
    },
    // 销量所属门店查询
    getsaleShopList() {
      let data = {}
      SalesShopInfoList(data).then(res=>{
        this.saleShopList = res.data.data
        this.saleShopList.forEach(item=>{
          item.id = String(item.id)
        })
        this.getDetail()
      })
    },
    // 更改所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      let obj = this.saleShopList.filter(it=>it.id ==  this.salesShopInfoId)
      this.salesShopCisCode = obj.length > 0 ? obj[0].cisCode :''

      if(this.$route.query.type!==3){
        this.getDMSBuyList()
        // this.productCode = ''
        // this.brandArr = []
        // this.form.brandCode = ''
        // this.form.brandName = ''
        // this.form.brandId = ''
        // this.logSpartName = ''
      }

    },
    // 修改服务类型
    changType(item,e) {
      item.installRepairType = e.target.value;
      this.getOtherProduct( item.installRepairType )
      if( item.installFlag == 0 &&  item.installRepairType == 1) {
        this.$message.warning('该产品无需安装，请重新选择产品')
      }
    },
    changeCheck(){
      this.pageLoadFlag = false
      if(this.flag){
        this.$router.go(-1);
      }

    },
    // 提交订单
    sumbit() {
      if(!this.form.name){
        this.$message.warning('请输入姓名')
        return
      }
      if(!this.form.phone){
        this.$message.warning('请输入手机号')
        return
      }
      if(!this.userDetailAddress){
        this.$message.warning('请输入详细地址')
        return
      }
      if(!this.province){
        this.$message.warning('请选择省市区')
        return
      }
      if(!this.receiveShop){
        this.$message.warning('请选择所属门店')
        return
      }
      if(!this.contactPersonTel){
        this.$message.warning('请输入门店电话')
        return
      }
      //  校验
      let checkNum = 0
      for(let i = 0;i< this.productShowList.length;i++) {
        const item = this.productShowList[i];
        if(item.installFlag == 0 && item.installRepairType == 1) {
          this.$message.warning('该产品无需安装，请重新选择产品')
          return;
        }
        if(!item.spartId && !item.productCode){
          this.$message.warning('请选择报装产品')
          return
        }
        if(!item.brandCode){
          this.$message.warning('请选择品牌')
          return
        }
        // if(!item.buyTime){
        //   this.$message.warning('请选择购买日期')
        //   return
        // }
        if(!item.reportRange){
          this.$message.warning('请选择预约时间')
          return
        }
        if( item.installRepairType==2 && !item.describe ){
          this.$message.warning('请输入故障描述')
          return
        }
        item.planTimeNew = ''
        if(item.reportRangeId == 2){
          item.planTimeNew = `${item.planTime} 12:30:00`
        }
        if(item.reportRangeId == 3){
          item.planTimeNew = `${item.planTime} 17:30:00`
        }
        if(item.reportRangeId == 4){
          item.planTimeNew = `${item.planTime} 20:30:00`
        }
        if(item.reportRangeId == 1){
          item.planTimeNew = `${item.planTime} 00:00:00`
        }
        // 增加校验
        let timeChoose = this.timeGroup.filter(it=>(it.id ==   item.reportRangeId))[0].name
        let timeCheck = timeChoose.split('-')[1]
        let time = `${item.planTime} ${timeCheck}`
        item.dataC = new Date(time.replace('-', '/')).getTime()
        item.now = new Date().getTime()
        if(item.dataC < item.now &&  item.reportRangeId != 1) {
          this.$message.warning('预约服务时间不可选择过去时间')
          return
        }
        checkNum +=item.num
      }
      if(checkNum>10){
        this.flag = false
        this.messageInfo = '单次提报产品数量合计不能超过10台'
        this.showMesaageFlag = true

        return
      }

      this.pageLoadFlag = true
      const productList = this.productShowList.map(item => {
        return {
          // ...item,
          productCode: item.productCode,
          logSpartName: item.logSpartName,
          spartName: item.spartName,
          spartCode: item.spartCode,    //这六个spart开头的参数与productCode、logSpartName只存其一即可    没有传null
          spartId: item.spartId,// 一级品类id           //这六个spart开头的参数与productCode、logSpartName只存其一即可    没有传null    数字类型别带双引号
          spartItemName: item.spartItemName,// 二级品类名           这六个spart开头的参数与productCode、logSpartName只存其一即可    没有传null
          spartItemCode: item.spartItemCode, //二级品类编码       这六个spart开头的参数与productCode、logSpartName只存其一即可    没有传null
          spartItemId: item.spartItemId, //二级品类id          这六个spart开头的参数与productCode、logSpartName只存其一即可    没有传null    数字类型别带双引号
          brandName: item.brandName, //品牌名 必传
          brandCode: item.brandCode, //品牌编码 必传
          brandId: item.brandId, //品牌id             必传     数字类型别带双引号},
          installRepairType: item.installRepairType, //1装   2修            数字类型别带双引号
          describe: item.describe, //描述（装20字，修30字）前端限制一下
          reportRange: item.reportRangeId, //1=全天;2=上午（截止到12:30）;3=下午（截止到17:30）;4=晚上（截止到20:30）;数字类型别带双引号
          reportDateStr:item.planTimeNew, //预约时间字符串，后面的时分秒根据reportRange字段值传。00:00:00=全天; 12:30:00=上午; 17:30:00=下午; 20:30:00=晚上
          scripUrl:item.fileListIds.length>0?item.fileListIds.join(','):'', //报修的时候文件路径，多个用逗号隔开，传字符串
          buyId:item.buyId,//购买记录表的id   不带双引号    购买记录接口返回值中list下的id就是
          buyDateStr:item.buyTime?item.buyTime+' 00:00:00':'',//购买时间
          num:item.num,   // 产品的数量   新增的字段
          id:item.id?item.id:''
        };
      });

       let data  = {
         "userAddress":this.userDetailAddress,//提报人填写的详细的地址
         "userPhone": this.form.phone, //目的用户的手机号
         "userName": this.form.name, //目的用户的姓名
         "shopPhone": this.contactPersonTel, //所属门店电话
         "provinceCode": this.province, //省code
         "cityCode": this.city, //市code
         "districtCode": this.area, //区code
         "townCode": this.Township, //街道（遇到没有街道的情况传null）
         "reportType": this.type, //来源  1工单  2订单  3购买记录    数字类型别带双引号
         "shopId":this.salesShopInfoId, //门店id，不带双引号，没有传null 与门店编码必传一个  俩都传也没事
         "shopCisCode":this.salesShopCisCode, //门店cis编码，取不到门店id时取这个           俩都传也没事
         "userId":this.userId?this.userId:null, //意向用户表的id   不带双引号
         productList:productList,
      }
       save(data).then(res=>{
         this.pageLoadFlag = false
         if(res.data.code == 0 ) {
           this.pageLoadFlag = true
           this.$message.success(res.data.msg)
           setTimeout(() => {
             this.$router.push({ path:'/installationRepairReporting/repairReportinglist'});
           }, 3000);
         } else {
           this.pageLoadFlag = false
           // this.$message.warning(res.data.msg)
           this.messageInfo = res.data.msg
           this.flag = true
           this.showMesaageFlag = true
         }
       })
   },
   // 智能识别
   recognitionFun(res){
     const townCode = res.levelAddress.town_code;
     // 收货地址
     this.addressHx = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
     // 收货地址 code
     this.province = townCode.slice(0,2).padEnd(12, 0);
     this.city = townCode.slice(0,4).padEnd(12, 0);
     this.area = townCode.slice(0,6).padEnd(12, 0);
     this.Township = townCode.padEnd(12, 0);
     // 详细地址
     this.userDetailAddress = res.address;
     // 姓名
     this.form.name = res.userName;
     // 电话
     this.form.phone = res.mobile;
   }
  }
};
