import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// 所属门店
export function SalesShopInfoList(data) {
  return http({
    url: '/reportInstallRepair/queryShopInfo.nd',
    method: 'post',
    data
  })
}

// 其他品类
export function querySpart(data) {
  return http({
    url: '/reportInstallRepair/querySpart.nd ',
    method: 'post',
    data
  })
}
// dms查询 已经购买产品
export function findRetailRecordByUserPhone(data) {
  return dmshttp({
    url: 'findRetailRecordByUserPhone',
    data
  })
}

// 根据型号名称查询品牌名
export function queryBrandByProductCode(data) {
  return http({
    url: '/reportInstallRepair/queryBrandByProductCode.nd',
    method: 'post',
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  })
}
// 根据品类id查询品牌名
export function queryBrandBySpartId(data) {
  return http({
    url: '/reportInstallRepair/queryBrandBySpartId.nd',
    method: 'post',
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',

  })
}
// 根据工单查询的id 查询详情
export function queryRepairOrderInfo(data) {
  return http({
    url: '/reportInstallRepair/queryRepairOrderInfo.nd',
    method: 'get',
    params:data
    // data,
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
  })
}
